/**
 * icon types = 'default', 'rounded', 'outline', 'sharp'
 *
 */
module.exports = {
  theme: 'clockwork',
  config: {
    show_utility_bar: false
  },
  icons: {
    type: 'outline',
    custom: {
      ic_tune: {
        viewbox: '0 0 16 16',
        content: '<path xmlns="http://www.w3.org/2000/svg" d="M3.00018 2H13.0002L7.99018 8.3L3.00018 2ZM0.250178 1.61C2.27018 4.2 6.00018 9 6.00018 9V15C6.00018 15.55 6.45018 16 7.00018 16H9.00018C9.55018 16 10.0002 15.55 10.0002 15V9C10.0002 9 13.7202 4.2 15.7402 1.61C16.2502 0.95 15.7802 0 14.9502 0H1.04018C0.210178 0 -0.259822 0.95 0.250178 1.61Z" fill="currentColor"/>'
      },
      ic_download: {
        viewbox: '0 0 16 16',
        content: '<path xmlns="http://www.w3.org/2000/svg" d="M14 11V14H2V11H0V14C0 15.1 0.9 16 2 16H14C15.1 16 16 15.1 16 14V11H14ZM13 7L11.59 5.59L9 8.17V0H7V8.17L4.41 5.59L3 7L8 12L13 7Z" fill="currentColor"/>'
      },
      ic_like: {
        viewbox: '0 0 16 20',
        content: '<path xmlns="http://www.w3.org/2000/svg" d="M9 9H7V12H4V14H7V17H9V14H12V12H9V9ZM10 0H2C0.9 0 0 0.9 0 2V18C0 19.1 0.89 20 1.99 20H14C15.1 20 16 19.1 16 18V6L10 0ZM14 18H2V2H9V7H14V18Z" fill="currentColor"/>'
      },
      ic_collections: {
        viewbox: '0 0 18 20',
        content: '<path xmlns="http://www.w3.org/2000/svg" d="M9 9H7V12H4V14H7V17H9V14H12V12H9V9ZM10 0H2C0.9 0 0 0.9 0 2V18C0 19.1 0.89 20 1.99 20H14C15.1 20 16 19.1 16 18V6L10 0ZM14 18H2V2H9V7H14V18Z" fill="currentColor"/>'
      },
      ic_view_stream: {
        viewbox: '0 0 18 18',
        content: '<path d="M16 10H2C0.9 10 0 10.9 0 12V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V12C18 10.9 17.1 10 16 10ZM16 16H2V12H16V16Z" fill="currentColor"/><path d="M16 0H2C0.9 0 0 0.9 0 2V6C0 7.1 0.9 8 2 8H16C17.1 8 18 7.1 18 6V2C18 0.9 17.1 0 16 0ZM16 6H2V2H16V6Z" fill="currentColor"/>'
      },
      ic_view_module: {
        viewbox: '0 0 18 18',
        content: '<path xmlns="http://www.w3.org/2000/svg" d="M0 0V8H8V0H0ZM6 6H2V2H6V6ZM0 10V18H8V10H0ZM6 16H2V12H6V16ZM10 0V8H18V0H10ZM16 6H12V2H16V6ZM10 10V18H18V10H10ZM16 16H12V12H16V16Z" fill="currentColor"/>'
      },
      ic_favorite_border: {
        viewbox: '0 0 20 19',
        content: '<path xmlns="http://www.w3.org/2000/svg" d="M14.5 0C12.76 0 11.09 0.81 10 2.09C8.91 0.81 7.24 0 5.5 0C2.42 0 0 2.42 0 5.5C0 9.28 3.4 12.36 8.55 17.04L10 18.35L11.45 17.03C16.6 12.36 20 9.28 20 5.5C20 2.42 17.58 0 14.5 0ZM10.1 15.55L10 15.65L9.9 15.55C5.14 11.24 2 8.39 2 5.5C2 3.5 3.5 2 5.5 2C7.04 2 8.54 2.99 9.07 4.36H10.94C11.46 2.99 12.96 2 14.5 2C16.5 2 18 3.5 18 5.5C18 8.39 14.86 11.24 10.1 15.55Z" fill="currentColor"/>'
      },
      ic_favorite: {
        viewbox: '0 0 20 19',
        content: '<path xmlns="http://www.w3.org/2000/svg" d="M10 18.35L8.55 17.03C3.4 12.36 0 9.28 0 5.5C0 2.42 2.42 0 5.5 0C7.24 0 8.91 0.81 10 2.09C11.09 0.81 12.76 0 14.5 0C17.58 0 20 2.42 20 5.5C20 9.28 16.6 12.36 11.45 17.04L10 18.35Z" fill="currentColor"/>'
      },
      ic_account_circle: {
        viewbox: '0 0 16 16',
        content: '<path xmlns="http://www.w3.org/2000/svg" d="M8 2c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2Zm0 10c2.7 0 5.8 1.29 6 2H2c.23-.72 3.31-2 6-2ZM8 0C5.79 0 4 1.79 4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4Zm0 10c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4Z" fill="currentColor"/>'
      },
      ic_exit_to_app: {
        viewbox: '0 0 18 18',
        content: '<path xmlns="http://www.w3.org/2000/svg" d="M14 5L12.59 6.41L14.17 8H6V10H14.17L12.59 11.58L14 13L18 9L14 5ZM2 2H9V0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H9V16H2V2Z" fill="currentColor"/>'
      }
    }
  }
}
